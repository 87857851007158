import React from 'react'
import { FacebookIcon, InstagramIcon, LinkedinIcon, Logo, TwitterIcon, YouTubeIcon } from '../assets'
import "./footer.component.css"
import { Button, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
const FooterSection = () => {

    const navigate = useNavigate();
    return (
        <div className="footer-container">
            <div className="footer-signup-container">
                <div className="flex flex-dir-col gp-10 aln-items-cnt">
                    <div className="footer-head-text">
                        <span className="head-txt-off-white">BEAT THE CROWD AND SIGN UP TO BE THE</span>
                        {/* be the first to be notified when ROKK is online */}
                        <span className="head-txt-primary">FIRST TO BE NOTIFIED WHEN ROKK IS ONLINE</span>
                    </div>

                    <span style={{ color: 'var(--white)' }}><span style={{ color: 'var(--offWhite)', textAlign: 'center' }}>By the</span> metal community, <span style={{ color: 'var(--offWhite)', textAlign: 'center' }}>for the</span> metal community</span>

                </div>
                <button className="btn primary-btn" style={{ minWidth: '150px' }}>Sign Up</button>
            </div>

            <div className="footer-content">
                <div className="footer-content-logo-socials">
                    <img src={Logo} alt="rokk_logo_compact" />
                    <div className="social-media-icons-container">
                        <li><img src={LinkedinIcon} alt="social_media_icons" /></li>
                        <li onClick={() => window.location.href = "https://www.youtube.com/@ROKKSTREAMING"}><img src={YouTubeIcon} alt="social_media_icons" /></li>
                        <li onClick={() => window.location.href = "https://www.instagram.com/rokkstreaming/"}><img src={InstagramIcon} alt="social_media_icons" /></li>
                        <li><img src={TwitterIcon} alt="social_media_icons" /></li>
                        <li onClick={() => window.location.href = "https://www.facebook.com/rokkstreamingapp"}><img src={FacebookIcon} alt="social_media_icons" /></li>
                    </div>
                </div>

                <div className="footer-nav-links flex jst-con-spc-btw">
                    <div>
                        <li onClick={() => navigate("../")}>Home</li>
                        <li onClick={() => navigate("../rokk")}>ROKK</li>
                        <li onClick={() => navigate("../pricing")}>Plan</li>
                        <li onClick={() => navigate("../faq")}>FAQ</li>
                        <li onClick={() => navigate("../artist")}>For Artist</li>
                    </div>
                    <div>
                        <li onClick={() => window.location.href="https://igg.me/at/rokkapp"}>Sign In</li>
                        <li onClick={() => window.location.href="https://igg.me/at/rokkapp"}>Sign Up</li>
                    </div>
                </div>

                <div className='footer-content-disclaimer'>

                    <Typography sx={{ marginBottom: '10px' }}>Legal disclaimer: </Typography>
                    <span style={{ color: 'var(--offWhite)' }}>
                        The information contained in this website is for general information purposes only. The information is provided by FAIRMUSIC AG and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                        <div>
                            In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                        </div>
                        Through this website you are able to link to other websites which are not under the control of FAIRMUSIC AG. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                        Every effort is made to keep the website up and running smoothly. However, FAIRMUSIC AG takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    </span>
                    <br />
                    <Button
                        className="company-information"
                        sx={{
                            marginTop: '15px',
                            color: 'rgba(255, 255, 255, 0.6)',
                            borderRadius: '30px',
                            padding: '10px 20px',
                            border: '1px solid rgba(255, 255, 255, 0.6)',
                            textTransform: 'none',
                            '&:hover': {
                                color: 'var(--primary)',
                                borderColor: 'var(--primary)'
                            }
                        }}
                        onClick={() => navigate("./company-info")}
                    >
                        Company Information
                        <ArrowForward className="icon" />
                    </Button>
                </div>

                <div className='terms-and-cond flex jst-con-spc-btw'>
                    <div>
                        © 2023 ROKK. All Right Reserved.
                    </div>
                    <div>
                        <li onClick={() => navigate("./privacy-policy")}>Privacy Policy</li>
                        <li>Terms of Use</li>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterSection