import React, { useState } from 'react'
import "./plans.component.css"
import { useStateContext } from '../context/StateContextAPI'
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Modal, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import { style } from './exports/plans-exports.component';
import AnimatedBorderSVG from './AnimatedBorderSVG';

import CurrencyData from "./exports/prices-exports.json";
import { plansfaq } from './exports/plans-faq-exports';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ButtonContainer = styled.div`
    display: flex;
    // flex-direction: column;
    gap: 10px;
    padding: 20px 0;
`;

const PlansCard = ({ type = "free", cardContents, currency, priceInfo }: any) => {

    const { plans, setPlans, countryCode } = useStateContext();
    const [isPlanAvailable, setIsPlanAvailable] = React.useState(true);
    React.useEffect(() => {
        const data = CurrencyData.filter((country: any) => country.Code === countryCode);
        if (data.length < 1) {
            setIsPlanAvailable(false);
        }
    }, [countryCode])

    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleAnnualBtn = () => {
        if (plans.includes('annual')) {
            let updatedPlans = plans.filter((item: string) => item !== 'annual');
            updatedPlans.push('month');
            setPlans(updatedPlans);
        }
        if (plans.includes('month')) {
            let updatedPlans = plans.filter((item: string) => item !== 'month');
            updatedPlans.push('annual');
            setPlans(updatedPlans);
        }
    }

    const handleStudentBtn = () => {
        let updatedPlans = plans.filter((item: string) => item !== 'student');
        if (!plans.includes('student')) {
            updatedPlans.push('student');
        }
        setPlans(updatedPlans);
    }

    // Take care of type === 'hifi'
    if (plansfaq.hasOwnProperty(type) && Array.isArray(plansfaq[type])) {
        return (
            <div className={`plans-cards-container ${type === 'hifi' ? ' activeCard' : ''}`}>

                <div className='plans-cards-title'>
                    {cardContents.title}
                    <InfoOutlined
                        sx={{
                            color: 'var(--light-gray)',
                            cursor: 'pointer'
                        }}
                        onClick={() => setOpenModal(true)} />
                    <Modal
                        open={openModal}
                        onClose={handleModalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        BackdropProps={{
                            style: {
                                backdropFilter: 'blur(4px)', // Apply the blur effect to the backdrop
                                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the background color of the backdrop
                            },
                        }}
                    >
                        <Box className="plans-cards"
                            sx={{ ...style, maxWidth: '600px' }}
                        >
                            <div className="animated-border" />
                            <svg className="animated-border-svg">
                                <AnimatedBorderSVG />
                            </svg>
                            <Box
                                sx={{
                                    background: 'var(--dark-color)',
                                    padding: '10px 20px',
                                    borderRadius: '10px'
                                }}
                            >
                                <Typography id="parent-modal-title"
                                    sx={{
                                        margin: '10px 0',
                                        color: 'var(--primary)',
                                        fontWeight: 600,
                                        fontSize: '18px'
                                    }}
                                >{cardContents.title}</Typography>
                                <Typography
                                    sx={{
                                        margin: '10px 0',
                                        color: 'var(--offWhite)',
                                        fontWeight: 500,
                                        fontSize: '15px'
                                    }}
                                >The most popular choice</Typography>
                                <div className="rokk-app-faq-questions-container">
                                    {plansfaq[type].map((quesData, idx) => {
                                        return (
                                            <Accordion
                                                key={idx}
                                                sx={{
                                                    backgroundColor: '#1a1a1a',
                                                    background: 'linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                                    borderRadius: '0',
                                                    '&.Mui-expanded': {
                                                        background: 'rgba(255, 255, 255, 0.15)'
                                                    }
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    sx={{
                                                        minHeight: '70px',
                                                        color: '#ffffff',
                                                        '&.Mui-expanded': {
                                                            color: '#ff8303',
                                                        },
                                                    }}
                                                >
                                                    <Typography>{quesData.ques}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        color: '#ffffff'
                                                    }}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: quesData.ans }} />
                                                </AccordionDetails>

                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </Box>
                        </Box>
                    </Modal>
                </div>

                <div
                    style={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        paddingBottom: '15px'
                    }}
                >
                    <div className="flex gp-10"
                        style={{
                            marginBottom: '-5px',
                            marginTop: '-10px'
                        }}
                    >
                        <span className="plans-cards-price-stroke">{currency} {priceInfo}</span>
                        <span className="plans-cards-per-month"> /Month</span>
                    </div>

                    <div style={{ color: 'var(--gray)' }}>
                        {type === "rokk-family" ? "5 Accounts, 30 days free trial" : "30 days free trial"}
                    </div>
                </div>

                <div className="flex flex-dir-col gp-10 plans-cards-benefits-section">
                    {cardContents.benefits.map((item: any, idx: number) => {
                        return (
                            <div style={{
                                display: 'flex',
                                gap: '12.5px'
                            }}>
                                <span style={{ fontSize: '20px', height: '15px', lineHeight: '22.5px' }}>&#x2022;</span>
                                <li key={idx} dangerouslySetInnerHTML={{ __html: item }} />
                            </div>
                        )
                    })}
                </div>

                <ButtonContainer>
                    <Button
                        variant='outlined'
                        sx={{
                            color: plans.includes('annual') ? 'var(--primary)' : 'var(--gray)',
                            border: plans.includes('annual') ? '0.1px solid var(--primary)' : '0.1px solid var(--gray)',
                            flex: 1,
                            fontSize: '13px',
                            padding: '10px 0',
                            marginTop: '-3px',

                            maxWidth: 'calc(50% - 5px)',
                            '&:hover': {
                                border: '0.1px solid var(--primary)',
                                color: 'var(--primary)'
                            },
                            '&.Mui-disabled': {
                                color: 'var(--light-gray)',
                                border: '0.1px solid var(--light-gray)'
                            }
                        }}
                        onClick={handleAnnualBtn}
                        disabled={!isPlanAvailable}
                    >
                        <Badge
                            sx={{
                                background: plans.includes('annual') ? 'var(--primary)' : 'var(--gray)',
                                width: '10px',
                                height: '10px',
                                marginRight: '10px',
                                borderRadius: '50%'
                            }}
                        >
                            &nbsp;
                        </Badge>
                        Annual (-16%)
                    </Button>
                    {type === 'hifi' || type === 'hifipro' ?
                        <Button
                            variant='outlined'
                            sx={{
                                color: plans.includes('student') ? 'var(--primary)' : 'var(--gray)',
                                border: plans.includes('student') ? '0.1px solid var(--primary)' : '0.1px solid var(--gray)',
                                flex: 1,
                                fontSize: '13px',
                                padding: '10px 0',
                                marginTop: '-3px',

                                maxWidth: 'calc(50% - 5px)',
                                '&:hover': {
                                    border: '0.1px solid var(--primary)',
                                    color: 'var(--primary)'
                                },
                                '&.Mui-disabled': {
                                    color: 'var(--light-gray)',
                                    border: '0.1px solid var(--light-gray)'
                                }

                            }}
                            onClick={handleStudentBtn}
                            disabled={!isPlanAvailable}
                        >
                            <Badge
                                sx={{
                                    background: plans.includes('student') ? 'var(--primary)' : 'var(--gray)',
                                    width: '10px',
                                    height: '10px',
                                    marginRight: '10px',
                                    borderRadius: '50%'
                                }}
                            >
                                &nbsp;
                            </Badge>
                            Student (-50%)
                        </Button>
                        :
                        ""
                    }

                </ButtonContainer>
                {/* <button className='secondary-btn-light'>FREE TRIAL</button> */}
                <Button
                    className="plans-button"
                    variant='contained'
                    sx={{
                        color: 'var(--white)',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        '&:hover': {
                            background: 'var(--primary)'
                        }
                    }}
                    onClick={() => window.location.href="https://igg.me/at/rokkapp"}
                >
                    {isPlanAvailable ? 'FREE TRIAL' : 'Available Soon'}
                </Button>
            </div>
        )
    } else {
        return <div>Type not found</div>;
    }
}

export default PlansCard;