import './App.css';
import { Routes, Route } from "react-router-dom"
import { FooterSection, NavbarComponent} from './components';
import { ArtistPage, CancelSubscription, CompanyInformationPage, DeleteAccount, FallbackPage, FaqPage, Home, LoginPage, PricingPage, PrivacyPolicyPage, RegisterBand, RegisterParty, RokkPage } from './pages';

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/artist" element={<ArtistPage />} />
        <Route path="/rokk" element={<RokkPage />} />
        <Route path="/register-new-artist/" element={<RegisterParty />} />
        <Route path="/register-new-band/" element={<RegisterBand />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/company-info" element={<CompanyInformationPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/cancel-subscription" element={<CancelSubscription />} />
        <Route path="*" element={<FallbackPage />} />
      </Routes>
      <FooterSection />
    </div>
  );
}

export default App;
